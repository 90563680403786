<template>
  <v-card>
    <!-- <v-card-title>{{ person.name.first }} {{ person.name.last }}</v-card-title> -->
    <v-row>
      <v-col cols="4" class="pr-0">
        <v-img class="align-end" :src="person.photo || '/img/no.jpg'" style="max-height:120px;max-width:150px;width:100%" contain />
      </v-col>
      <v-col cols="8" style="max-width:calc(66.6666666667% - 12px)">
        <h3 v-if="person.name" style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis">{{ person.name.first }} {{ person.name.last }}</h3>
        <div v-if="person.jobTitle">{{ person.jobTitle }}</div>
        <div v-if="person.location && person.location.department">{{ person.location.department }}</div>
        <div v-if="person.email" style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis">
          <a :href="'mailto:' + person.email">{{ person.email }}</a>
        </div>
        <div v-if="person.phone">
          <a :href="'tel:' + person.phone">{{ person.phone }}</a>
        </div>
        <div v-if="person.location && person.location.room">
          {{ person.person === 'Student' ? 'Room' : 'Office' }}: {{ person.location.room === 'OC' ? 'Off-Campus' : person.location.room }}
        </div>
        <div v-if="person.location && person.location.box">
          Campus Box #{{ person.location.box.replace(/[^0-9]/g, '')}}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    person: {
      type: Object,
      default: () => {
        return {
          photo: null,
          name: {
            first: '',
            last: ''
          },
          jobTitle: null,
          location: {
            department: null,
            room: null
          },
          email: null,
          phone: null,
          person: ''
        }
      }
    }
  }
}
</script>
